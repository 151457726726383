import { APPLY_NOW, FRONTEND_ENGINEER, FULL_TIME, NIGERIA, ROLE_DESCRIPTION } from '../constants'

const OpenRolesCard = () => {
  return (
    <div className=' rounded-2xl p-3 md:p-6 justify-between md:items-center w-full flex flex-col shadow-lg bg-white'>
      <div className='md:px-6 px-2 py-4'>
        <div className='font-bold text-2xl mb-2'>{FRONTEND_ENGINEER}</div>
        <p className='text-vdfHtexts text-sm tracking-wider max-w-3xl mb-4'>{ROLE_DESCRIPTION}</p>
        <div className='flex items-center mb-2'>
          <span className='text-black font-semibold flex gap-2 items-center text-lg'>
            {FULL_TIME} <span className='text-primary  text-lg'>&bull;</span> {NIGERIA}
          </span>
        </div>
      </div>
      <div className=''>
        <button className='btn-primary text-lg text-white mb-5 md:mb-0 bg-primary py-2 md:mr-5'>
          {APPLY_NOW}
        </button>
      </div>
    </div>
  )
}

export default OpenRolesCard
