import { notification, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import { dispatch } from '../../../redux/store'

export const AdminCustumerModuleActions = ({ record, permissions }) => {
  const handleUpdateStatus = async id => {
    try {
      await dispatch.users.updateUserActiveStatus({
        user_id: id,
        enablement_status: record.is_disabled ? 0 : 1 // Enable if disabled, disable if enabled
      })
      dispatch.users.getAllusers({ showAll: true })
      notification.success({
        message: 'Success',
        description: `User ${record.is_disabled ? 'enabled' : 'disabled'} successfully.`
      })
    } catch (err) {
      notification.error({
        message: 'Action Failed',
        description: `Failed to ${
          record.is_disabled ? 'enable' : 'disable'
        } user. Please try again later.`
      })
    }
  }

  const confirmAction = () => {
    handleUpdateStatus(record.id)
  }

  const cancelAction = () => {
    notification.info({
      message: 'Action Cancelled',
      description: 'User status remains unchanged.'
    })
  }
  return (
    <DropDown>
      <Link
        to={`/admin-customers-details/${record.id}`}
        style={{ outline: 0 }}
        className='w-full text-left nav-sm-link'
      >
        <i className='far fa-eye mr-2' /> View Details
      </Link>
      <Link
        to={`/admin-create-customer-account/${record.id}`}
        style={{ outline: 0 }}
        className='w-full text-left nav-sm-link'
      >
        <i className='fas fa-plus mr-2' /> Manage Bank
      </Link>
      <Popconfirm
        title={`Are you sure you want to ${
          record.is_disabled ? 'Enable' : 'Disable'
        } this customer?`}
        onConfirm={confirmAction}
        onCancel={cancelAction}
        okText='Yes'
        cancelText='No'
      >
        <button
          style={{ outline: 0 }}
          className='w-full hover:text-blue-500 ease-in-out text-left nav-sm-link'
        >
          <i className='fas fa-ban mr-2' />{' '}
          {record.is_disabled ? 'Enable Customer' : 'Disable Customer'}
        </button>
      </Popconfirm>
    </DropDown>
  )
}

AdminCustumerModuleActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    is_disabled: PropTypes.bool.isRequired
  }).isRequired,
  permissions: PropTypes.object
}
