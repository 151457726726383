import { notification } from 'antd'

import { Loans } from '../../services'
import { reducerActions } from '../reducer'
import { applyLoanlocalStorageKey } from '../../pages/admin-pages/apply-for-loan/constants'
import { transformBusinessInformation } from '../../utils/helpers'

const initialState = {
  loanApplication: {},
  isServerError: null,
  disbursedLoan: [],
  customerLoansById: {},
  repaymentHistory: [],
  lineChart: null,
  barChart: null,
  approvedLoanUsers: [],
  currentUserIDExpanded: '',
  viewLoanInterestPaymentTracker: []
}

export const loans = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async applyForLoan(payload, rootState) {
      dispatch.loans.setError(null)
      try {
        let data
        const { history, ...others } = payload
        if (rootState.auth.authUser.userInfo.is_admin) {
          const response = await Loans.adminApplyForLoan(others)
          data = response.data
        } else {
          const response = await Loans.customerApplyForLoan(others)
          data = response.data
        }
        if (!data?.success) {
          notification.error({
            message: 'An error occured'
          })
          return null
        }

        return data
      } catch (error) {
        dispatch.loans.setError(error.response)
        if (error?.response?.data?.message?.includes('under review')) {
          notification.error({
            message: 'An error occured',
            description:
              error?.response?.data?.message ||
              error?.message ||
              error?.data?.message ||
              'Something went wrong'
          })
          window.localStorage.removeItem(applyLoanlocalStorageKey)
          payload.history.push('/admin-pending?shouldUseCache=false')
        } else {
          return null
        }
      }
    },
    async applyForExistingCustomer(payload) {
      dispatch.loans.setError(null)

      try {
        const { data } = await Loans.applyForExistingCustomer(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Loan applied successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while applying a Loan'
          })
        }
        return { data }
      } catch (error) {
        dispatch.loans.setError(error)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
        return { error }
      }
    },
    async getCustomerDisbursedLoan() {
      dispatch.loans.setError(null)
      try {
        const { data } = await Loans.getCustomerLoans()
        if (data.success) {
          dispatch.loans.setState({ disbursedLoan: data.data })
        } else {
          notification.error({
            message: 'An error occured',
            description: data?.message || 'No result found. Kindly check back later'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async getApprovedLoansUsers() {
      dispatch.loans.setError(null)
      try {
        const { data } = await Loans.getApprovedLoanUsers()
        dispatch.loans.setState({ approvedLoanUsers: data.data.approved_loan_users })
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async getCustomerLineChart(payload) {
      dispatch.loans.setError(null)
      try {
        const { data } = await Loans.getCustomerLinechartA(payload)
        if (data.success) {
          dispatch.loans.setState({ lineChart: { ...data.data } })
        } else {
          notification.error({
            message: 'An error occured',
            description: data?.message || 'No result found. Kindly check back later'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async getCustomerBarChart(payload) {
      dispatch.loans.setError(null)
      try {
        const { data } = await Loans.getCustomerBarchartB(payload)
        if (data.success) {
          dispatch.loans.setState({ barChart: { ...data.data } })
        } else {
          notification.error({
            message: 'An error occured',
            description: data?.message || 'No result found. Kindly check back later'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async disburseLoan(payload) {
      dispatch.loans.setError(null)
      try {
        // Open loans are disbursed loan and are in progress
        const { data } = await Loans.disbursedLoan(payload)
        if (data.success) {
          dispatch.loans.setState({ disbursedLoan: data.data })
          notification.success({
            message: 'Success',
            description: data?.message || 'Loan disbursed successfully'
          })
          await dispatch.applications.adminGetLoanByStatus({
            shouldUseCache: false,
            status: 'approved'
          })
          dispatch.applications.adminGetLoanByStatus({ shouldUseCache: false, status: 'disbursed' })
          return true
        } else {
          throw new Error(data?.mesage)
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        const message =
          error?.response?.data?.message ||
          error?.response?.data?.msg ||
          error?.response?.message ||
          error?.data?.message ||
          error?.message ||
          'Something went wrong'
        throw new Error(message)
      }
    },
    async viewLoanInterestTrackerPayment(payload) {
      dispatch.loans.setError(null)
      try {
        const { data } = await Loans.viewLoanInterestTracker(payload)
        if (data.success) {
          dispatch.loans.setState({
            viewLoanInterestPaymentTracker: data.data.interest_tracker,
            viewLoanInterestPaymentTrackerTotal: data.data.totals
          })
          notification.success({
            message: 'Success',
            description: data?.message
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
      }
    },
    async getUserLoans(payload, rootState) {
      dispatch.loans.setError(null)
      dispatch.loans.setState({
        currentUserIDExpanded: payload.record.id
      })
      // check if we already have the user Id in the state with loans
      const foundUserId = Object.keys(rootState?.loans.customerLoansById).find(
        id => id === payload.record.id
      )
      if (foundUserId && !payload?.refresh) {
        // stop the function if found
        return
      }

      try {
        const { data } = await Loans.getUserLoansByUserId(payload.record.id)
        if (data.success) {
          const transformedUserLoans = data.data?.loan_data?.map(loan => ({
            ...loan,
            ...transformBusinessInformation(data.data?.business_info || loan)
          }))
          dispatch.loans.setState({
            customerLoansById: {
              [payload.record.id]: transformedUserLoans,
              ...rootState.loans.customerLoansById
            }
          })
        } else {
          notification.error({
            message: 'An error occured',
            description: data?.message
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.loans.setState(initialState)])
    }
  }),
  // https://rematchjs.org/docs/plugins/select/
  selectors: (slice, createSelector, hasProps) => ({
    getUserLoansById() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.loans
        (rootState, props) => rootState?.loans.currentUserIDExpanded,
        (loans, userId) => {
          for (const id in loans.customerLoansById) {
            if (Object.hasOwnProperty.call(loans.customerLoansById, id)) {
              if (id === userId) {
                return loans.customerLoansById[id]
              }
            }
          }
          Object.keys(loans.customerLoansById).filter(id => id === userId)
        }
      )
    },
    selectLoansErrors() {
      return slice(loans => loans.isServerError)
    }
  })
}
