import React from 'react'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import PushNotificationComponent from './PushNotification'

const Messages = () => {
  return (
    <DashboardLayout>
      <div>
        <PushNotificationComponent />
      </div>
    </DashboardLayout>
  )
}

export default Messages
