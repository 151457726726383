import { HOW_WE_CREATE, HOW_WE_CREATE_PARAGRAPH } from '../constants'
import howWeCreate from '../assets/howWeCreate.svg'

const HowWeCreate = () => {
  return (
    <main className='py-5 md:py-16'>
      <div>
        <h1 className='text-lg text-black md:text-3xl tracking-wider max-w-3xl ml-4 md:ml-0 font-semibold'>
          {HOW_WE_CREATE}
        </h1>
      </div>
      <div className='flex flex-col lg:flex-row mt-3 md:gap-20 md:mt-12'>
        <div className='mt-3 md:mt-0   w-full lg:w-1/3'>
          <p className='md:max-w-2xl text-vdfHtexts font-sans2 leading-relaxed md:leading-loose text-lg px-2 md:px-0 md:text-xl md:tracking-wider  md:mt-8'>
            {HOW_WE_CREATE_PARAGRAPH}
          </p>
        </div>
        <div className='w-full mt-5 md:mt-0 lg:w-3/5'>
          <img src={howWeCreate} alt='Our Mission' className='w-full' />
        </div>
      </div>
    </main>
  )
}

export default HowWeCreate
