import React, { useState } from 'react'
import MonoConnect from '@mono.co/connect.js'
import { dispatch } from '../../redux/store'
import { MONO_KEY } from '../../utils/constants'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
function Mono({ shouldShowbutton, text }) {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [bankStatementDuration, setStatementDuration] = useState('last6months')

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onSuccess: function ({ code }) {
        dispatch.auth.getBankStatement({ code, bankStatementDuration })
      },
      key: MONO_KEY
    })
    monoInstance.setup()
    return monoInstance
  }, [bankStatementDuration])

  return (
    <>
      <Modal
        onOk={() => {
          setModalVisibility(false)
          monoConnect.open()
        }}
        open={modalVisibility}
        onCancel={() => setModalVisibility(false)}
      >
        <div>
          <h1 className='text-base '>
            Vittas will only pull your bank statement. We don&lsquo;t have access to your account.
            Please select duration and click Ok to continue
          </h1>
          <div className='mt-5 flex w-full flex-col content-end items-end shadow-sm p-4'>
            <p className='w-2/3 text-primary'>Please choose statement duration</p>
            <select
              className='w-2/3 border p-2'
              defaultValue='last6months'
              onChange={e => {
                setStatementDuration(e.target.value)
              }}
            >
              <option className=' text-primary' value='last6months'>
                6 Months Statement
              </option>
              <option className=' text-primary' value='last9months'>
                9 Months Statement
              </option>
              <option className=' text-primary' value='last12months'>
                12 Months Statement
              </option>
            </select>
          </div>
        </div>
      </Modal>

      <button
        onClick={() => setModalVisibility(true)}
        className={shouldShowbutton ? 'mono-btn' : 'mono-link-btn'}
      >
        {text || 'Link account with Mono'}
      </button>
    </>
  )
}
Mono.propTypes = {
  shouldShowbutton: PropTypes.bool,
  text: PropTypes.string
}
export default Mono
