/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _debounce from 'lodash/debounce'
import { DatePicker, Table } from 'antd'
import ReactPaginate from 'react-paginate'

import { dispatch } from '../../../redux/store'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { SYSTEM_LOGS_ARR, SYSTEM_LOGS } from './constants'

const SystemLogs = () => {
  const history = useHistory()
  const searchInput = useRef(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterDateRange, setFilter] = useState(null)
  const [systemLogsData, setSystemLogsData] = useState([])
  const [searchText, setSearchText] = useState(null)

  const { logsList, logLoading, isServerError, pagination } = useSelector(({ loading, logs }) => ({
    logLoading: loading.effects.logs.getSystemLogs,
    logsList:
      logs?.paginatedSystemLogs
        ?.map(({ log_id, ...more }) => ({
          key: log_id,
          log_id,
          ...more
        }))

        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) || [],
    pagination: logs?.pagination,
    isServerError: logs.isServerError
  }))

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const handleSearch = async ({ target }) => {
    setSearchText(target.value)
    await dispatch.logs.getSystemLogs({
      search: target.value
    })
  }

  const handleSearchDate = async (_, date) => {
    if (!date || !_) {
      return resetFilters()
    }

    setFilter(date)

    await dispatch.logs.getSystemLogs({
      startDate: date[0],
      endDate: date[1]
    })
  }
  const resetFilters = () => {
    setSearchText('')
    setFilter(null)
    setSystemLogsData([])
    dispatch.logs.getSystemLogs()
  }

  const handlePageClick = event => {
    const page = event.selected + 1
    dispatch.logs.getSystemLogs({
      page,
      startDate: filterDateRange ? filterDateRange[0] : undefined,
      endDate: filterDateRange ? filterDateRange[1] : undefined,
      search: searchText ? searchText : undefined
    })
  }

  const searchLogsDebounce = _debounce(handleSearch, 400)

  useEffect(() => {
    if ((!logsList?.length || isServerError) && !logLoading) {
      dispatch.logs.getSystemLogs()
    }
    if (!logLoading && Boolean(logsList?.length)) {
      setSystemLogsData(logsList)
    }
  }, [logLoading])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-between mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {SYSTEM_LOGS}
          </h3>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-1 text-lg mx-4 mb-4'>
          <div className='relative mt-6 border rounded-lg mr-6 ml-3 col-span-3'>
            <div className='absolute top-4 left-3 pl-2'>
              <button onCanPlay={() => searchInput.current.focus()}>
                <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
              </button>
            </div>
            <input
              type='text'
              ref={searchInput}
              onChange={searchLogsDebounce}
              className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
              placeholder='Search by action, event url, customer id etc'
            />
          </div>
          <div className='flex mt-6'>
            <span className=' mt-4'>Filter by:</span>
            <div className='relative flex border rounded-md  ml-3'>
              <DatePicker.RangePicker
                type='date'
                onChange={handleSearchDate}
                className=' h-14 w-60 pl-2 pr-8 text-center rounded-md z-0 focus:shadow focus:outline-none'
              />
            </div>
          </div>
          <button
            className='btn-primary border-primary border hover:bg-primary text-primary px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center hover:text-white'
            onClick={resetFilters}
          >
            Reset
          </button>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <Table
            rowClassName=''
            className='mt-5'
            rowSelection={rowSelection}
            columns={SYSTEM_LOGS_ARR}
            dataSource={systemLogsData}
            loading={logLoading}
            pagination={false}
          />
        </div>

        <ReactPaginate
          breakLabel='...'
          className='react-paginate'
          nextLabel='next >'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.total_pages}
          previousLabel='< previous'
          renderOnZeroPageCount={null}
        />
      </main>
    </DashboardLayout>
  )
}

export default SystemLogs
