import React, { useEffect, useState } from 'react'
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import MoneySend from '../../../../../images/money-send.svg'
import Empty from '../../../../../images/EmptyState.svg'
import ArrowUpIcon from '../../../../../images/ArrowUp.png'
import CopyIcon from '../../../../../images/copy.png'
import NewPin from '../modal/NewPin'
import CreateCorperateAccount from '../modal/CreateCorperateAccount'
import { adminServices } from '../../../../../services'
import { notification } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { copyToClipboard, saftyReturnComponent } from '../../../../../utils/helpers'
import TransferModal from '../../account-transfer/modal/TransferModal'
import { Icon } from '@iconify/react'
import queryString from 'query-string'

const VfdAccount = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [consentUrl, setConsentUrl] = useState(false)
  const [isTransferPinModalVisible, setIsTransferPinModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [deactivateAccountPndLoading, setDeactivateAccountPndLoading] = useState(false)
  const [transactionsLoading, setTransactionsLoading] = useState(true)
  const [accountBalanceLoading, setAccountBalanceLoading] = useState(true)
  const [showCreateCorperateAccountModal, setShowCreateCorperateAccountModal] = useState(false)
  const [customeBizInfo, setCustomeBizInfo] = useState({})
  const [customerVdfAccountInfoInhouse, setCustomerVdfAccountInfoInhouse] = useState({})
  const [vdfAccountInfo, setVdfAccountInfo] = useState({})
  const [customerVdfAccountBalance, setCustomerVdfAccountBalance] = useState({})
  const [customerVfdTransactions, setCustomerVfdTransactions] = useState([])
  const [customerVfdAccountCreationEligibility, setCustomerVfdAccountCreationEligibility] =
    useState([])
  const userIdFromParams = useParams().id
  const [copied, setCopied] = useState(false)
  const [transferModalVisible, setTransferModalVisible] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)
  const [showBvn, setShowBvn] = useState(false)
  const history = useHistory()

  const handleCopyLink = () => {
    copyToClipboard(consentUrl, 'Consent URL copied successfully!')
    setCopied(true)
  }

  const copyAccountNumberToClipboard = accountNumber => {
    copyToClipboard(accountNumber, 'Account number copied successfully!')
  }

  const handlePndRelease = async () => {
    await deactivateAccountPnd()
  }
  const getCustomerDBizInfo = async () => {
    const { data } = await adminServices.getCustomerBizInfo(userIdFromParams)
    if (data.success) {
      setCustomeBizInfo(data.data.business_information)
    } else {
      notification.error({
        message: 'An error occured',
        description: data?.message || 'This User Id does not have a business information'
      })
    }
  }

  const getCustomerVdfAccountInhouseInformation = async () => {
    try {
      const { data } = await adminServices.getCustomerVdfAccountInformation(userIdFromParams, 'VFD')
      if (data.success) {
        setCustomerVdfAccountInfoInhouse(data.data)
      }
    } catch (err) {}
  }

  const getVdfAccountInformation = async () => {
    try {
      const { data } = await adminServices.getCustomerVfdAccountBalance(userIdFromParams)
      if (data.success) {
        setVdfAccountInfo(data.data)
        return data.data
      }
    } catch (err) {}
  }

  const getCustomerVfdAccountCreationEligibility = async () => {
    try {
      const { data } = await adminServices.getCustomerVfdAccountCreationEligibility(
        userIdFromParams
      )
      if (data.success) {
        setCustomerVfdAccountCreationEligibility(data.data)

        if (data.data.length) return false
        return true
      }
    } catch (err) {
      return false
    }
  }

  const getCustomerVfdTransactions = async () => {
    try {
      const { data } = await adminServices.getCustomerVfdTransactions(userIdFromParams)
      if (data.success) {
        setCustomerVfdTransactions(data.data?.result)
        setTransactionsLoading(false)
      }
    } catch (err) {
      setTransactionsLoading(false)
    } finally {
      setTransactionsLoading(false)
    }
  }

  const getCustomerVfdAccountBalance = async () => {
    try {
      const { data } = await adminServices.getCustomerVfdAccountBalance(userIdFromParams)
      if (data.success) {
        setCustomerVdfAccountBalance(data.data)
        setAccountBalanceLoading(false)
      }
    } catch (err) {
      setAccountBalanceLoading(false)
    } finally {
      setAccountBalanceLoading(false)
    }
  }

  const handleCreateAccountClick = requiredFields => {
    if (!requiredFields) {
      setModalVisible(true)
    } else {
      history.replace(`/admin-customers-details/${userIdFromParams}`)
    }
  }
  const handleNewPinContinue = () => {
    // Set the state to show the CreateCorperateAccount modal
    setModalVisible(false)
    setShowCreateCorperateAccountModal(true)
  }

  const handleTransferNewPinContinue = () => {
    setIsTransferPinModalVisible(false)
    setTransferModalVisible(true)
  }

  const handleCreateCorperateAccountCancel = () => {
    setShowCreateCorperateAccountModal(false)
  }
  const handleTransferClick = () => {
    setIsTransferPinModalVisible(true)
  }

  const deactivateAccountPnd = async () => {
    setDeactivateAccountPndLoading(true)
    try {
      const { data } = await adminServices.deactivateAccountPnd(userIdFromParams)
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data?.message || 'PND Released successfully'
        })
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message || 'Something went wrong!'
        })
      }

      setDeactivateAccountPndLoading(false)
    } catch (err) {
      setDeactivateAccountPndLoading(false)
      notification.error({
        message: 'An error occured',
        description: err?.response?.data?.message || 'Something went wrong!'
      })
    }
  }

  const getCustomerConsent = async () => {
    try {
      const { data } = await adminServices.getCustomerConsent(userIdFromParams)

      if (data.success) {
        setConsentUrl(data?.data?.consent_url?.url)
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message || 'Something went wrong!'
        })
      }
    } catch (err) {}
  }

  const routeToReceiptPage = data => {
    const query = queryString.stringify({
      ...data,
      sender: customeBizInfo?.vfd_data?.companyName
    })
    const path = `/receipt-page/${query ? `?${query}` : ''}`
    history.push(path)
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }, [copied])

  useEffect(() => {
    async function fetch() {
      try {
        getCustomerDBizInfo()
        const vdfAccount = await getVdfAccountInformation()

        if (!vdfAccount) {
          await getCustomerVfdAccountCreationEligibility()
        } else {
          // NOTE - Await is needed here to simulate a smooth loading
          await getCustomerVdfAccountInhouseInformation()
          setLoading(false)
          await getCustomerVfdAccountBalance()
          setAccountBalanceLoading(false)
          await getCustomerVfdTransactions()
          setTransactionsLoading(false)
          await getCustomerConsent()
        }

        setLoading(false)
        setAccountBalanceLoading(false)
        setTransactionsLoading(false)
      } catch (err) {}
    }

    fetch()
  }, [refreshKey])

  return (
    <main className='px-4 w-full py-8'>
      <div className='flex w-full space-x-8'>
        <div className='w-full max-w-sm'>
          <div className='flex justify-between items-center'>
            <h1 className=' text-xl text-vdfHtexts  font-semibold '>Account</h1>
            <span className=''>
              <EllipsisOutlined />
            </span>
          </div>
          <div className='p-5 flex w-full mt-4 max-w-sm flex-col py-7  rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base font-semibold leading-normal'>
              Account Details
            </h1>
            {loading ? (
              <div>
                <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
              </div>
            ) : customerVdfAccountInfoInhouse?.accountNo ? (
              <div className='flex flex-col w-full gap-2'>
                <div className='flex gap-4 justify-between select-none'>
                  <p className='text-gray-400'>Account Name </p>
                  <div className='flex gap-2 cursor-pointer'>
                    <p className='font-bold'>{vdfAccountInfo?.savingsProductName}</p>
                  </div>
                </div>

                <div className='flex gap-4 justify-between select-none'>
                  <p className='text-gray-400'>Account Number </p>
                  <div
                    className='flex gap-2 cursor-pointer'
                    onClick={() =>
                      copyAccountNumberToClipboard(customerVdfAccountInfoInhouse?.accountNo)
                    }
                  >
                    <p className='font-bold'>{customerVdfAccountInfoInhouse?.accountNo}</p>
                    <div>
                      <img src={CopyIcon} alt='copy icon' />
                    </div>
                  </div>
                </div>
                <div className='flex gap-4 justify-between select-none'>
                  <p className='text-gray-400'>BVN </p>
                  <div className='flex gap-2'>
                    {!showBvn ? (
                      <p>*** *** ***</p>
                    ) : (
                      <p className='font-bold'>{customerVdfAccountInfoInhouse?.bvn}</p>
                    )}

                    <div>
                      <Icon
                        icon={showBvn ? 'el:eye-close' : 'el:eye-open'}
                        color='#b1b1ff'
                        fontSize='20'
                        className='cursor-pointer'
                        onClick={() => setShowBvn(!showBvn)}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex gap-4 justify-between select-none'>
                  <p className='text-gray-400'>Account type </p>
                  <p className='font-bold'>Cooperate</p>
                </div>
                <div className='flex gap-4 justify-between select-none'>
                  <p className='text-gray-400'>Bank</p>
                  <p className='font-bold'>VFD</p>
                </div>
                {!customerVdfAccountInfoInhouse?.is_bvn_consent && (
                  <div className='flex gap-4 justify-between select-none items-center'>
                    <p className='text-gray-400'>BVN consent URL</p>
                    <button
                      type='button'
                      className={`${
                        copied ? 'bg-green-800' : 'bg-blue-800'
                      }  text-white px-4 py-2 mt-2`}
                      onClick={handleCopyLink}
                    >
                      {copied ? 'Copied' : 'Copy Link'}
                    </button>
                  </div>
                )}
                {/* eslint-disable */}
                {customerVdfAccountInfoInhouse?.is_bvn_consent ? (
                  !customerVdfAccountInfoInhouse?.is_pnd_released ? (
                    <div className='flex gap-4 justify-between select-none items-center'>
                      <p className='text-gray-400'>Release from PND</p>
                      <button
                        type='button'
                        className={`${'bg-blue-800'}  text-white px-4 py-2 mt-2`}
                        onClick={handlePndRelease}
                      >
                        {deactivateAccountPndLoading ? <LoadingOutlined spin /> : 'Release'}
                      </button>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className='flex justify-center flex-col mb-4 items-center mt-8'>
                <h1 className='text-center text-black text-sm font-normal'>
                  {!customerVfdAccountCreationEligibility?.length ? (
                    <p>No account created yet</p>
                  ) : (
                    <div>
                      <p className='text-primary'>
                        Before you create an account for this customer make sure you update the
                        customer&apos;s info, these information are misssing{' '}
                      </p>
                      <div className='font-bold text-red-500 flex flex-col items-start flex-wrap'>
                        {customerVfdAccountCreationEligibility?.map((field, index) => (
                          <div key={index} className='flex items-center gap-2'>
                            <div className='w-2 h-2 bg-red-600 rounded-full inline-block' />
                            <span>{field}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </h1>
                <button
                  onClick={() =>
                    handleCreateAccountClick(customerVfdAccountCreationEligibility?.length)
                  }
                  className='text-white px-4 mt-4 text-base font-normal py-3 bg-indigo-700 rounded justify-center items-center leading-normal gap-2.5 inline-flex'
                >
                  {customerVfdAccountCreationEligibility?.length ? (
                    <span>Update Customer Information</span>
                  ) : (
                    <span>Create Account</span>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className='p-5 flex w-full max-w-sm flex-col py-7 mt-8 rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base  font-semibold leading-normal'>
              Account Balance
            </h1>
            {accountBalanceLoading ? (
              <div>
                <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
              </div>
            ) : (
              <div className='text-indigo-700  mt-8 text-2xl font-semibold'>
                NGN {(customerVdfAccountBalance?.accountBalance || 0.0).toFixed(2)}
              </div>
            )}
          </div>
          <div className='p-5 flex w-full max-w-sm flex-col py-7 mt-8  rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base font-semibold leading-normal'>Loan Balance</h1>
            <div className='text-indigo-700 mt-8 text-2xl font-semibold'>-</div>
          </div>
        </div>
        <div className='w-full max-w-xs'>
          <h1 className=' text-vdfHtexts text-xl font-semibold'>Actions</h1>
          <div
            onClick={
              customerVfdAccountCreationEligibility?.length ||
              !customerVdfAccountInfoInhouse?.accountNo
                ? undefined
                : () => handleTransferClick()
            }
            className={`p-5 px-8 flex w-full border border-white hover:bg-blue-300
             hover:border-blue-800 max-w-sm flex-col py-7 mt-7 cursor-pointer  rounded-lg bg-vdfBoxBg 
             ${
               customerVfdAccountCreationEligibility?.length ||
               !customerVdfAccountInfoInhouse?.accountNo
                 ? 'cursor-not-allowed'
                 : ''
             } `}
          >
            <h1 className='text-base ml-4 font-semibold leading-normal text-vdfHtexts'>Transfer</h1>
            <div className='text-indigo-700  ml-4 mt-8 text-2xl font-semibold'>
              <img src={MoneySend} alt='icon' />
            </div>
          </div>
        </div>
        <div className='w-full'>
          <h1 className=' text-vdfHtexts text-xl font-semibold'>Transaction History</h1>
          {transactionsLoading ? (
            <div className='p-5 flex w-full max-w-sm flex-col py-7 mt-8 rounded-lg bg-vdfBoxBg'>
              <div>
                <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
              </div>
            </div>
          ) : customerVfdTransactions?.length ? (
            <div className='p-5 px-8  flex w-full max-w-2xl flex-col py-5 mt-7  rounded-lg bg-vdfBoxBg'>
              {customerVfdTransactions?.map(trx => (
                <div
                  className='flex justify-between items-center shadow-md p-4 rounded-md flex-col lg:flex-row cursor-pointer'
                  key={trx?.transactionId}
                  onClick={() => routeToReceiptPage(trx)}
                >
                  <div className='flex gap-4 items-center'>
                    <div>
                      <img src={ArrowUpIcon} alt='arrow up icon' className='' />
                    </div>

                    <div>
                      <p className='font-bold mb-0'>Transfer to {trx?.walletName?.toUpperCase()}</p>
                      <p className='text-gray-400 mb-0'>{trx?.time}</p>
                    </div>
                  </div>
                  <div>
                    <p className='font-bold mb-0'>{trx?.amount}</p>
                    <div>
                      {trx?.transactionResponse === '00' ? (
                        <span className='text-green-600'>Successful</span>
                      ) : (
                        <span className='text-red-500'>Failed</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='p-5 px-8 flex w-full max-w-2xl flex-col py-20 mt-7  rounded-lg bg-vdfBoxBg'>
              <h1 className='text-black text-xl ml-20 mt-8 font-semibold'>Actions</h1>
              <div className='text-indigo-700 self-center  ml-4  text-2xl font-semibold'>
                <img src={Empty} alt='icon' />
              </div>
              <div className='text-center'>
                <span className=' text-vdhTexts text-xl font-bold  leading-[34.24px]'>
                  Oops!
                  <br />
                </span>
                <span className=' text-vdhTexts text-base font-normal  leading-7'>
                  No transaction yet
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {saftyReturnComponent(
        isModalVisible,
        <NewPin
          visible={isModalVisible}
          onCancel={() => setModalVisible(false)}
          title='Verification Pin'
          description='Enter your pin to continue'
          onContinue={handleNewPinContinue}
        />
      )}
      {saftyReturnComponent(
        isTransferPinModalVisible,
        <NewPin
          visible={isTransferPinModalVisible}
          onCancel={() => setIsTransferPinModalVisible(false)}
          title='Verification Pin'
          description='Enter your pin to continue'
          onContinue={handleTransferNewPinContinue}
        />
      )}
      {saftyReturnComponent(
        showCreateCorperateAccountModal,
        <CreateCorperateAccount
          visible={showCreateCorperateAccountModal}
          onCancel={handleCreateCorperateAccountCancel}
          title='Create Corporate Account'
          customeBizInfo={customeBizInfo}
          setRefreshKey={setRefreshKey}
        />
      )}
      {saftyReturnComponent(
        transferModalVisible,
        <TransferModal
          visible={transferModalVisible}
          onCancel={() => setTransferModalVisible(false)}
          title='Transfer to'
          setRefreshKey={setRefreshKey}
        />
      )}
    </main>
  )
}

export default VfdAccount
