import React from 'react'
import { GetStartedHeading, GetStartedParagraph } from './constant'
import GetStartedBG from '../../images/GetStartedBG.svg'
import { useHistory } from 'react-router'

const GetStarted = () => {
  const history = useHistory()
  const handleGetStartedClick = () => {
    history.push('/login') // Navigate to the login page
  }
  return (
    <div className='w-full flex justify-center py-12 md:mt-6'>
      <div
        className='bg-newBgBlue w-full  max-w-3xl rounded-2xl space-y-6 flex flex-col items-center py-12 justify-center bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url(${GetStartedBG})` }}
      >
        <h1 className='font-sans2 md:text-4xl font-black text-white'>{GetStartedHeading}</h1>
        <p className='text-GetStrParagraph font-semibold font-sans2 md:text-xl  leading-loose text-center max-w-xl'>
          {GetStartedParagraph}
        </p>
        <button
          onClick={handleGetStartedClick}
          className='bg-white px-4 py-2 my-3 text-primary rounded-2xl hover:bg-gray-400 text-lg hover:border-white hover:text-white'
        >
          Get Started
        </button>
      </div>
    </div>
  )
}

export default GetStarted
