import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ icon, title, content }) => {
  return (
    <div className='rounded-lg max-w-lg p-4 md:mt-6 shadow-lg'>
      <div className='p-2 ml-3'>{icon}</div>
      <div className='bg-white  rounded-b-lg p-6'>
        <h2 className='text-xl text-Heading font-semibold'>{title}</h2>
        <p className='text-header-txt max-w-sm tracking-widest text-sm md:text-lg mt-3'>
          {content}
        </p>
      </div>
    </div>
  )
}
Card.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}

export default Card
