import { notification, Popconfirm } from 'antd'
import { bool, string, func } from 'prop-types'
import React from 'react'
import { Applications } from '../../services'

const DeleteLoan = ({ id, canDeleteLoan, callback }) => {
  const handleDelete = async () => {
    try {
      await Applications.delete_loan(id)
      if (callback) {
        await callback()
      }
      notification.success({
        message: 'Successfully deleted'
      })
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.response?.message
      })
    }
  }

  const cancel = () => {
    notification.warn({
      message: 'Document not deleted',
      placement: 'bottomRight'
    })
  }

  return (
    <Popconfirm
      title='Are you sure you want to delete this loan'
      onConfirm={handleDelete}
      onCancel={cancel}
      okText='Yes'
      disabled={!canDeleteLoan}
      cancelText='No'
    >
      <span className='w-full text-left nav-sm-link hover:text-blue-800'>
        <i className='fa fa-recycle mr-2' aria-hidden='true' />
        Delete loan
      </span>
    </Popconfirm>
  )
}

export default DeleteLoan
DeleteLoan.propTypes = {
  id: string,
  canDeleteLoan: bool,
  callback: func
}
