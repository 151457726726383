import React, { useEffect, useState } from 'react'
import filterImg from '../../../images/icons/filter.svg'
import { UPCOMINGPAYMENT } from './constant'
import useGetLoanStatus from '../../../pages/admin-pages/applications/useGetLoanStatus'
import { filterUpcomingPayment } from '../../../utils/helpers'
import dayjs from 'dayjs'

const UpcomingPaymentTable = () => {
  const { disbursed: disbursedLoanData, loading } = useGetLoanStatus('disbursed')

  const [upcomingPayments, setUpcomingPayments] = useState([])
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (disbursedLoanData.length && !upcomingPayments.length) {
      setUpcomingPayments(filterUpcomingPayment(disbursedLoanData))
    }
  }, [disbursedLoanData])

  const visiblePayments = showAll ? upcomingPayments : upcomingPayments.slice(0, 5)

  return (
    <div className='w-full h-full p-2'>
      <div className='flex justify-between mb-4'>
        <div className='text-lg font-medium'>{UPCOMINGPAYMENT}</div>
        <div className='flex items-center gap-2 mr-8'>
          <img src={filterImg} />
          <div className='text-gray-500'>filter</div>
        </div>
        <button
          className='text-blue-800 text-lg mr-6 border-b-2 border-blue-600'
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'View less' : 'View All'}
        </button>
      </div>

      <table className='border-separate border-0 flex flex-col'>
        <tbody>
          {!loading &&
            visiblePayments?.map((item, index) => (
              <tr key={index} className='h-full mt-2 flex justify-between items-center'>
                <td className='w-full md:w-60'>
                  <div className='font-bold w-48'>{item?.business_name}</div>
                  <div>Disbursed Date: {dayjs(item?.disbursed_date).format('MM-DD-YYYY')}</div>
                  <div>Next Interest Date: {item?.next_interest_date}</div>
                </td>
                <td className='mt-4'>
                  <span
                    className={
                      item.dueSoon
                        ? 'text-yellow-600 bg-yellow-100 px-3 py-1 rounded-md text-xs mr-2'
                        : 'text-red-600 bg-red-100 px-3 py-1 rounded-md text-xs mr-2'
                    }
                  >
                    {item.dueSoon ? 'Due soon' : item.overDue ? 'overdue' : ''}
                  </span>
                </td>
                <td className=''>
                  <div className='w-40'>
                    <button className='px-4 py-1 border border-blue-800 bg-blue-50 text-primary hover:text-white rounded-lg hover:bg-primary text-sm'>
                      View transaction
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default UpcomingPaymentTable
