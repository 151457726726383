/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dispatch } from '../../../redux/store'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import AppTable from '../../../sharedComponents/table/AppTable'
import { ADMIN_APPROVED_APPLICATIONS as allApprovedApplications } from '../../applications/constant'
import _debounce from 'lodash/debounce'
import { LOAN_APPLICATION } from './constants'
import useGetLoanStatus from './useGetLoanStatus'
import { searchArrayOrMakeCallToAPI } from '../../../utils/helpers'
import { notification } from 'antd'

const AdminApprovedApp = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [approvedAppData, setApprovedAppData] = useState([])
  const [reason, setReason] = useState('')

  const { permissions } = useSelector(({ auth }) => auth.authUser)
  const { approved: approvedLoanData, loading } = useGetLoanStatus('approved')

  const loadingSearch = useSelector(({ loading }) => loading.effects.applications.searchApplication)
  const searchInput = useRef(null)

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleDelete = async data => {
    if (!reason || reason.length < 3) {
      notification.warn({
        message: 'Please provide a valid reason'
      })

      return
    }
    await dispatch.applications.unApproveLoan({ loanId: data.approved_loan_id, reason })
  }

  const handleCancel = () => {}

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const handleSearch = async ({ target }) => {
    if (!target.value) {
      return setApprovedAppData(approvedLoanData)
    }
    const resultFound = await searchArrayOrMakeCallToAPI({
      array: approvedLoanData,
      searchTerm: target.value,
      makeSearchCall: []
    })
    return setApprovedAppData(resultFound)
  }

  const searchUserDebounce = _debounce(handleSearch, 400)

  useEffect(() => {
    setApprovedAppData(approvedLoanData)
  }, [loading])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {LOAN_APPLICATION}
          </h3>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-1 text-lg mx-4'>
          <div className='relative mt-6 border rounded-lg mr-6 ml-3 col-span-3'>
            <div className='absolute top-4 left-3 pl-2'>
              <button onCanPlay={() => searchInput.current.focus()}>
                <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
              </button>
            </div>
            <input
              type='text'
              ref={searchInput}
              onChange={searchUserDebounce}
              className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
              placeholder='Search by amount, business name, etc'
            />
          </div>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <AppTable
            columns={allApprovedApplications(
              permissions,
              {
                searchInput,
                handleSearch: handleSearchTable,
                handleReset,
                setSearchText,
                searchedColumn,
                searchText,
                setSearchedColumn
              },
              { setReason, handleCancel, handleDelete }
            )}
            tableData={approvedAppData?.filter(item => Boolean(item?.approved_loan_id))}
            loading={loading || loadingSearch}
          />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AdminApprovedApp
