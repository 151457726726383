import { JOIN_THE_TEAM, OPEN_ROLES } from '../constants'
import OpenRolesCard from './OpenRolesCard'

const OpenRoles = () => {
  return (
    <main className='py-5 md:mt-6 md:py-16'>
      <div>
        <h1 className='text-lg md:text-2xl tracking-wider max-w-3xl ml-4 md:ml-0 font-bold'>
          {OPEN_ROLES}
        </h1>
        <p className='md:text-lg text-sm text-vdfHtexts px-2 md:px-0 ml-2 md:ml-0 mt-2 font-normal max-w-xl tracking-wider md:mt-3'>
          {JOIN_THE_TEAM}
        </p>
      </div>
      <div className='w-full px-4 md:px-0 mt-4 md:mt-9 mx-auto'>
        <OpenRolesCard />
      </div>
    </main>
  )
}
export default OpenRoles
