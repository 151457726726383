/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { FormSelect } from '../../../sharedComponents/form-select'
import AddNewDirector from './AddNewDirector'
import { InputField } from '../../../sharedComponents/input-field'
import {
  BIRTHDATE,
  EMAIL,
  LAST_NAME,
  FIRST_NAME,
  PHONE_NO,
  BANK_VERIFICATION_NO,
  YEARS_OF_EXPERIENCE,
  HOME_ADDRESS,
  BROWSE_FILE,
  NO_OF_KIDS,
  STATE_OF_ORIGIN,
  LOCAL_GOVT
} from '../../../sharedComponents/entries/constant'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Directors, Upload } from '../../../services'
import { Popconfirm, Spin, Tooltip, notification } from 'antd'
import { validateFileTypes } from '../../../utils/helpers'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import PreviewModal from '../apply-for-loan/New-apply-loan/modal/PreviewModal'
import { dispatch } from '../../../redux/store'
import { RightOutlined } from '@ant-design/icons'
import EmailVerificationMaodal from './EmailVerifyModal'
import useTogglePasswordVisibility from '../../../hooks/useTogglePasswordVisibility'
import PasswordTogglerLabel from '../../../sharedComponents/others/PasswordTogglerLabel'

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  id: '',
  birth_date: '',
  bank_veri_no: '',
  address: '',
  gender: '',
  years_of_experience: '',
  marital_status: '',
  num_kids: '',
  photo: '',
  government_id: '',
  title: '',
  education_level: '',
  state_of_origin: '',
  local_govt: ''
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  birth_date: Yup.date().required('Birthdate is required'),
  bank_veri_no: Yup.string().required('Bank verification number is required'),
  address: Yup.string().required('Home address is required'),
  gender: Yup.string().required('Gender is required'),
  title: Yup.string().required('Title is required'),
  num_kids: Yup.string().required('Number of kids is required'),
  education_level: Yup.string().required('Educational level is required'),
  years_of_experience: Yup.number()
    .typeError('Years of experience must be a number')
    .required('Years of experience is required')
    .min(0, 'Years of experience cannot be negative')
    .max(99, 'Years of experience cannot exceed 99'),
  marital_status: Yup.string().required('Marital status is required'),
  photo: Yup.string().required('Passport Photograph is required'),
  government_id: Yup.string().required('Government ID is required'),
  local_govt: Yup.string().required('Local Govt is required'),
  state_of_origin: Yup.string().required('State of origin is required')
})

const CustomerDir = () => {
  const [customerDir, setCustomerDir] = useState([])
  const [shouledEdit, setShouldEdit] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [uploading, setUploading] = useState({})
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const userIdFromParams = useParams().id
  const [emailVerifyModal, setEmailVerifyModal] = useState(false)
  const [currentDirId, setCurrentDirId] = useState('')
  const { isPasswordVisible, togglePasswordVisibility } = useTogglePasswordVisibility()
  const { isAdmin, token, permissions, loadingDirector, sendingVeirifcation } = useSelector(
    ({ auth: { authUser }, loading }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      data: authUser.userInfo?.data,
      token: authUser.token,
      permissions: authUser.permissions,
      loadingDirector: loading.effects.directors.customerUpdateDirector,
      sendingVeirifcation: loading.effects.directors.resendConfirmationEmail
    })
  )
  const [formikInitialValues, setFormikInitialValues] = useState({ ...initialValues })

  const handleDelete = async id => {
    await dispatch.directors.customerDeleteDirector({
      token,
      permissions,
      id,
      isAdmin
    })
    await getCustomerDir()
  }
  const cancel = () => {
    notification.warn({
      message: 'Director not deleted',
      placement: 'bottomRight'
    })
  }
  const getCustomerDir = async () => {
    const { data } = await Directors.admin_get_customer_director(userIdFromParams)
    if (data.success) {
      setFormikInitialValues({ ...formikInitialValues, ...data.data.user_directors[0] })
      setCurrentDirId(data.data.user_directors[0]?.id)
      setCustomerDir(
        data.data.user_directors?.filter(
          dir => dir.activation_status !== 'Deactivated' || !dir.activation_status
        )
      )
    } else {
      notification.error({
        message: 'An error occured',
        description: data?.message
      })
    }
  }

  const handleSubmit = async values => {
    const details = isAdmin ? { ...values, director_id: values.id } : values

    const endpoint = isAdmin ? 'admin/update-director' : 'customer/update-director'
    if (!_.isEqual(formikInitialValues, values)) {
      await dispatch.directors.customerUpdateDirector({
        token,
        permissions,
        details,
        endpoint
      })

      await getCustomerDir()
    } else {
      notification.warn({
        message: 'Error',
        description: 'Please make a change'
      })
    }
  }

  const handleUploadFile = (file, setFileInFormik, fieldName) => {
    if (
      !validateFileTypes(file, ['jpeg', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'png', 'jpg', 'webp'])
    ) {
      notification.error({
        message: 'Invalid file type',
        description:
          'Please upload a valid file type, we only accept images, pdfs, excel files and docs'
      })
      return
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)
    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        setFileInFormik(fieldName, data.url)
        setPreviewFileUrl(data.url) // Set the preview URL
      })
      .catch(error => {
        setFileInFormik(fieldName, '')
        setUploading({ ...uploading, [fieldName]: '' })
        notification.error({
          message: 'Error',
          description: error.message
        })
      })
  }

  const handleCloseEmailVerifyModal = () => {
    setEmailVerifyModal(false)
  }
  const handleEmailVerifyModal = () => {
    setEmailVerifyModal(true)
  }
  const onSelectDirector = ({ target }) => {
    const currentDir = customerDir.find(dir => dir.id === target.value)
    if (currentDir.activation_status === 'Deactivated' || !currentDir.activation_status) {
      return notification.error({
        message: 'This director has been deactivated'
      })
    }
    setFormikInitialValues(currentDir)
    setCurrentDirId(target.value)
  }

  const verifyDirEmail = async () => {
    await dispatch.directors.resendConfirmationEmail({ email: formikInitialValues.email })
    setEmailVerifyModal(false)
  }

  useEffect(() => {
    getCustomerDir()
  }, [])

  return (
    <main className='w-full flex-grow p-4'>
      <div className=' w-full mt-8 mb-16 px-4 overflow-hidden'>
        <div className='flex justify-between flex-wrap'>
          <div className='w-full md:flex justify-between px-2 mb-6'>
            <div className='flex items-center gap-2 justify-between'>
              <div className=' w-full ml-5 md:w-72'>
                <FormSelect
                  className='outline-none  py-3 px-8 bg-white '
                  name='id'
                  onChange={onSelectDirector}
                  label='select director'
                  options={customerDir.map(
                    ({ first_name: firstName, last_name: lastName, id, ...others }) => ({
                      name: `${firstName + ' ' + lastName}`,
                      value: id
                    })
                  )}
                />
              </div>
              <div>
                <div className='w-full mt-3 ml-3'>
                  {formikInitialValues?.email_verified === 1 && (
                    <button className='btn-secondary pointer-events-none flex items-center gap-2 bg-green-700 px-4 py-1 text-white'>
                      Verified
                    </button>
                  )}

                  {(formikInitialValues?.email_verified === 0 ||
                    !formikInitialValues?.email_verified) && (
                    <Tooltip title='Send verification email'>
                      <button
                        onClick={handleEmailVerifyModal}
                        className='btn-secondary flex items-center rounded gap-2 bg-red-500 px-3 py-1 text-white shadow-lg'
                      >
                        Not verified {sendingVeirifcation ? <Spin /> : <RightOutlined />}
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className='md:flex justify-between '>
              <div>
                <AddNewDirector
                  getCurrentUserDirs={getCustomerDir}
                  text={<span className='mb- text-primary text-lg'>Add New Director</span>}
                />
              </div>
              <div className='mt-3'>
                {Boolean(formikInitialValues.email) && (
                  <div className='border rounded-sm border-primary text-lg px-2 py-1'>
                    {!shouledEdit ? (
                      <button className='text-primary' onClick={() => setShouldEdit(true)}>
                        Edit Information
                      </button>
                    ) : (
                      <label htmlFor='handleSubmit' className=' cursor-pointer text-primary'>
                        Update Director
                        {loadingDirector ? <Spin size='small' /> : ''}
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className='w-full my-8 px-4'>
                  <div className='flex justify-between flex-wrap'>
                    <div className='w-full  mx-2'>
                      <span className='text-base font-bold'>
                        Kindly provide the following documents:
                      </span>
                      <div className='mt-4'>
                        <div className='md:grid grid-cols-3 flex-grow gap-2 gap-x-6'>
                          {/* First Name */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              First name
                            </label>
                            <Field
                              disabled={!shouledEdit}
                              value={values.first_name}
                              type='text'
                              name='first_name'
                              maxLength='35'
                              placeholder={FIRST_NAME}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='first_name'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Last Name */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>Last name</label>
                            <Field
                              disabled={!shouledEdit}
                              value={values.last_name}
                              type='text'
                              name='last_name'
                              maxLength='35'
                              placeholder={LAST_NAME}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='last_name'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Gender */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>Gender</span>
                            <Field
                              disabled={!shouledEdit}
                              value={values.gender}
                              as='select'
                              name='gender'
                              className='form-control bg-white'
                            >
                              <option value=''>Select gender</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </Field>
                            <ErrorMessage
                              name='gender'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Marital Status */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              Marital status
                            </span>
                            <Field
                              disabled={!shouledEdit}
                              value={values.marital_status}
                              as='select'
                              name='marital_status'
                              className='form-control bg-white'
                            >
                              <option value=''>Marital Status</option>
                              <option value='Single'>Single</option>
                              <option value='Married'>Married</option>
                              <option value='Others'>Others</option>
                            </Field>
                            <ErrorMessage
                              name='marital_status'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Education level */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              Education level
                            </span>
                            <Field
                              as='select'
                              disabled={!shouledEdit}
                              value={values.education_level}
                              name='education_level'
                              className='form-control bg-white'
                            >
                              <option value=''>Select one</option>
                              <option value='None'>None</option>
                              <option value='Higer Education'>Higer Education</option>
                              <option value='Bachelor'>Bachelor</option>
                              <option value='Masters'>Masters</option>
                              <option value='Ph.D'>Ph.D</option>
                            </Field>
                            <ErrorMessage
                              name='education_level'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Title */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>Title</span>
                            <Field
                              as='select'
                              disabled={!shouledEdit}
                              value={values.title}
                              name='title'
                              className='form-control bg-white'
                            >
                              <option value=''>Select title</option>
                              <option value='Mr'>Mr</option>
                              <option value='Chief'>Chief</option>
                              <option value='Engr'>Engr</option>
                              <option value='Dr'>Dr</option>
                              <option value='Prof'>Prof</option>
                              <option value='Master'>Master</option>
                              <option value='Miss'>Miss</option>
                            </Field>
                            <ErrorMessage name='title' component='small' className='text-red-500' />
                          </div>
                          {/* Email */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>Email</label>
                            <Field
                              disabled={!shouledEdit}
                              type='text'
                              name='email'
                              value={values.email}
                              maxLength='45'
                              placeholder={EMAIL}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage name='email' component='small' className='text-red-500' />
                          </div>

                          {/* Phone Number */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              Phone number
                            </label>
                            <Field
                              disabled={!shouledEdit}
                              type='text'
                              name='phone'
                              value={values.phone}
                              maxLength='15'
                              placeholder={PHONE_NO}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage name='phone' component='small' className='text-red-500' />
                          </div>

                          {/* Home Address */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              Home address{' '}
                            </label>
                            <Field
                              disabled={!shouledEdit}
                              type='text'
                              value={values.address}
                              name='address'
                              placeholder={HOME_ADDRESS}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='address'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Birthdate */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>Birthdate</label>
                            <Field
                              disabled={!shouledEdit}
                              type='date'
                              name='birth_date'
                              max={new Date().toISOString().slice(0, 10)}
                              placeholder={BIRTHDATE}
                              value={values.birth_date}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='birth_date'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Years of Experience */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              Year of Experience
                            </label>
                            <Field
                              disabled={!shouledEdit}
                              type='text'
                              name='years_of_experience'
                              value={values.years_of_experience}
                              maxLength='2'
                              placeholder={YEARS_OF_EXPERIENCE}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='years_of_experience'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Number of kids */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              Number of Kids
                            </label>
                            <Field
                              type='text'
                              name='num_kids'
                              maxLength='2'
                              placeholder={NO_OF_KIDS}
                              as={InputField}
                              disabled={!shouledEdit}
                              value={values.num_kids}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='num_kids'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Bank Verification Number */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              Bank verification number{' '}
                              <PasswordTogglerLabel
                                action={togglePasswordVisibility}
                                passwordVisible={isPasswordVisible}
                              />
                            </span>
                            <Field
                              disabled={!shouledEdit}
                              type={isPasswordVisible ? 'text' : 'password'}
                              name='bank_veri_no'
                              maxLength='11'
                              value={values.bank_veri_no}
                              placeholder={BANK_VERIFICATION_NO}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='bank_veri_no'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* State of origin */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              State of origin
                            </span>
                            <Field
                              type='text'
                              name='state_of_origin'
                              value={values.state_of_origin}
                              disabled={!shouledEdit}
                              placeholder={STATE_OF_ORIGIN}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='state_of_origin'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Local Govt */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>Local Govt</span>
                            <Field
                              type='text'
                              name='local_govt'
                              value={values.local_govt}
                              disabled={!shouledEdit}
                              placeholder={LOCAL_GOVT}
                              as={InputField}
                              className='form-control bg-white'
                            />
                            <ErrorMessage
                              name='local_govt'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          <div className='w-full max-w-sm '>
                            {/* <div className='flex flex-col'> */}
                            <div className='relative'>
                              <label
                                htmlFor='images'
                                className='block text-black text-base leading-8'
                              >
                                Passport Photograph
                              </label>
                              <input
                                disabled={!shouledEdit}
                                type='file'
                                id='photo_id'
                                name='photo'
                                accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                onChange={event => {
                                  const file = event.target.files[0]
                                  setUploading({
                                    ...uploading,
                                    photo: file?.name
                                  })

                                  handleUploadFile(file, setFieldValue, 'photo')
                                }}
                                className='mt-1 sr-only form-control' // Hide the default input appearance
                              />
                              <label
                                htmlFor='photo_id'
                                className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                              >
                                {/* If not uploading, then make text to be browse file */}
                                {!values.photo && uploading.photo === undefined && BROWSE_FILE}
                                {values.photo && (
                                  // Show "View" link when a file is uploaded
                                  <a
                                    href='#'
                                    onClick={event => {
                                      event.preventDefault()
                                      setPreviewFileUrl(values.photo)
                                      setModalVisible(true)
                                    }}
                                    className='pr-2 text-blue-700 '
                                  >
                                    View
                                  </a>
                                )}
                                {/* if uploaded, then change text to change file */}
                                {Boolean(values.photo) && (
                                  <>
                                    <span className='pr-3 hidden'> {uploading['forms.photo']}</span>{' '}
                                    <span className='text-blue-700 underline'>Change file</span>
                                  </>
                                )}
                                {/* if its uploading, then change text to uploading */}
                                {uploading.photo && !values.photo && 'Uploading...'}
                              </label>
                            </div>
                            <ErrorMessage name='photo' component='small' className='text-red-500' />
                            {/* </div> */}
                          </div>
                          <div className='w-full max-w-sm '>
                            <div className='relative'>
                              <label
                                htmlFor='government_id'
                                className='block text-black text-base leading-8'
                              >
                                Government ID
                              </label>
                              <input
                                disabled={!shouledEdit}
                                type='file'
                                id='government_id'
                                name='government_id'
                                accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                onChange={event => {
                                  const file = event.target.files[0]
                                  setUploading({
                                    ...uploading,
                                    government_id: file?.name
                                  })

                                  handleUploadFile(file, setFieldValue, 'government_id')
                                }}
                                className='mt-1 sr-only form-control' // Hide the default input appearance
                              />
                              <label
                                htmlFor='government_id'
                                className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                              >
                                {/* If not uploading, then make text to be browse file */}
                                {!values.government_id &&
                                  uploading.government_id === undefined &&
                                  BROWSE_FILE}
                                {values.government_id && (
                                  // Show "View" link when a file is uploaded
                                  <a
                                    href='#'
                                    onClick={event => {
                                      event.preventDefault()
                                      setPreviewFileUrl(values.government_id)
                                      setModalVisible(true)
                                    }}
                                    className='pr-2 text-blue-700 '
                                  >
                                    View
                                  </a>
                                )}
                                {/* if uploaded, then change text to change file */}
                                {Boolean(values.government_id) && (
                                  <>
                                    <span className='pr-3 hidden'>
                                      {' '}
                                      {uploading['forms.government_id']}
                                    </span>
                                    <span className='text-blue-700 underline'>Change file</span>
                                  </>
                                )}
                                {/* if its uploading, then change text to uploading */}
                                {uploading.government_id && !values.government_id && 'Uploading...'}
                              </label>
                            </div>
                            <ErrorMessage
                              name='government_id'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='  w-full flex justify-center '>
                  <input
                    hidden
                    id='handleSubmit'
                    type='submit'
                    className='text-white mb-10 text-lg bg-primary-200 rounded py-2 px-5'
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div>
            <Popconfirm
              title='Are you sure to delete this director?'
              onCancel={cancel}
              onConfirm={() => handleDelete(currentDirId)}
              okText='Yes'
              cancelText='No'
            >
              <button className='btn-primary-200  transition-all duration-300 active:bg-red-700 bg-red-800'>
                Deactivate Director
              </button>
            </Popconfirm>
          </div>
          <PreviewModal
            modalVisible={modalVisible}
            previewFileUrl={previewFileUrl}
            setModalVisible={setModalVisible}
          />
        </div>
      </div>
      {emailVerifyModal && (
        <EmailVerificationMaodal
          sendingVeirifcation={sendingVeirifcation}
          onClose={handleCloseEmailVerifyModal}
          onSubmit={verifyDirEmail}
        />
      )}
    </main>
  )
}

CustomerDir.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    birth_date: PropTypes.string,
    bank_veri_no: PropTypes.string,
    address: PropTypes.string,
    gender: PropTypes.string,
    years_of_experience: PropTypes.string,
    marital_status: PropTypes.string,
    education_level: PropTypes.string,
    photo: PropTypes.string,
    government_id: PropTypes.string,
    no_of_kids: PropTypes.string,
    num_kids: PropTypes.string
  })
}
export default CustomerDir
