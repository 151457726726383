import { BENEFITS, BENEFITS_CARD_DATA, BENEFITS_PARAGRAPGH } from '../constants'
import Card from './BenefitsCards'

const Benefits = () => {
  return (
    <main className='py-5 md:mt-6 md:py-16'>
      <div>
        <h1 className='text-xl text-black font-bold md:text-3xl tracking-wider max-w-3xl ml-4 md:ml-0'>
          {BENEFITS}
        </h1>
        <p className='md:text-lg text-sm text-vdfHtexts px-2 md:px-0 ml-2 md:ml-0 mt-2 font-normal max-w-xl md:mt-3'>
          {BENEFITS_PARAGRAPGH}
        </p>
      </div>
      <div className='grid justify-center mt-4 gap-8 items-center grid-cols-1 px-4 md:px-0  md:ml-8 md:grid-cols-2 lg:grid-cols-3'>
        {BENEFITS_CARD_DATA.map((value, index) => (
          <Card
            key={index}
            icon={<img src={value.icon} className='' />}
            title={value.title}
            content={value.content}
          />
        ))}
      </div>
    </main>
  )
}
export default Benefits
