import React from 'react'
import PropTypes from 'prop-types'

const PasswordTogglerLabel = ({ passwordVisible, action }) => {
  return (
    <small className='text-primary-200 cursor-pointer select-none' onClick={action}>
      {passwordVisible ? 'Hide' : 'Reveal'}
    </small>
  )
}

export default PasswordTogglerLabel

PasswordTogglerLabel.propTypes = {
  passwordVisible: PropTypes.bool,
  action: PropTypes.Function
}
