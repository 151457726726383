/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import { useParams } from 'react-router'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import {
  APPLICATION_DATE,
  BIZ_DESCRIPTION,
  BIZ_DOCUMENTS,
  BIZ_EMAIL_ADDRESS,
  BIZ_INFORMATION,
  BIZ_NAME,
  BIZ_PHONE_NO,
  BIZ_SIGNATORY,
  BIZ_SIZE,
  BIZ_FOUNDED_YEAR,
  BIZ_RC_NUMBER,
  GENDER,
  CONFIRMATION_STATUS,
  DATE_OF_BIRTH,
  GO_BACK,
  HOUSE_ADDRESS,
  INCORPORATION_DATE,
  INDUSTRY,
  MARITAL_STATUS,
  MEMORANDUM_AND_ARTICLE,
  PARTICULARS_OF_DIRECTORS,
  PHARMACISTS,
  REG_ADDRESS,
  // REQUIRED_BIZ_LICENSE,
  SIX_MONTHS_BANK_STATEMENT,
  VIEW_APPLICATION,
  NO_OF_KIDS,
  CHANGE_OF_REG_ADDRESS,
  APPOINTMENT_OF_DIR,
  STATEMENT_SHARE_CAP,
  GOVERNMENT_ID
} from './constant'
import pdfImage from '../../images/pdf.jpg'
import loader from '../../images/load.gif'
import imageUrl from '../../images/icons8-repeat-30.png'
import { dispatch, select } from '../../redux/store'
import { useSelector } from 'react-redux'
import { formatCurrency, validateURL } from '../../utils/helpers'
import { downloadCSV } from '../../sharedComponents/export/bankStatement'
import { bankStatementColumn } from '../admin-pages/customers/constants'
import LoanTypeModal from '../admin-pages/apply-for-loan/New-apply-loan/modal/LoanTypeModal'

export const DETAILS = [
  { title: BIZ_NAME, key: 'business_name' },
  { title: BIZ_EMAIL_ADDRESS, key: 'business_email' },
  { title: BIZ_PHONE_NO, key: 'business_phone_number' },
  { title: INDUSTRY, key: 'business_loan_industry' },
  { title: BIZ_SIZE, key: 'business_num_employee' },
  { title: BIZ_DESCRIPTION, key: 'business_description' },
  { title: REG_ADDRESS, key: 'business_street_address' },
  { title: INCORPORATION_DATE, key: 'business_incorp_date' }
]

export const NEW_BUSINESS_INFORMATION = [
  { title: BIZ_NAME, key: 'buz_name' },
  { title: BIZ_FOUNDED_YEAR, key: 'buz_foundind_year' },
  { title: BIZ_EMAIL_ADDRESS, key: 'buz_email' },
  { title: BIZ_PHONE_NO, key: 'buz_phone' },
  { title: INDUSTRY, key: 'buz_loan_industry' },
  { title: BIZ_SIZE, key: 'buz_no_of_staff' },
  { title: BIZ_DESCRIPTION, key: 'buz_description' },
  { title: BIZ_RC_NUMBER, key: 'buz_rc_number' },
  { title: REG_ADDRESS, key: 'buz_address' },
  { title: INCORPORATION_DATE, key: 'buz_incorp_date' }
]

export const PREVIEW_DETAILS = [
  { title: BIZ_NAME, key: 'bizName' },
  { title: BIZ_EMAIL_ADDRESS, key: 'bizEmail' },
  { title: BIZ_PHONE_NO, key: 'bizPhone' },
  { title: INDUSTRY, key: 'bizIndustry' },
  { title: BIZ_SIZE, key: 'noOfEmployee' },
  { title: BIZ_DESCRIPTION, key: 'bizDescription' },
  { title: REG_ADDRESS, key: 'bizLocation' },
  { title: INCORPORATION_DATE, key: 'incorporationDate' }
]

const ApplicationsDetails = () => {
  const [previewModalVisible, setModalVisible] = useState(false)
  const history = useHistory()
  const details = useSelector(({ applications }) => applications.appDetails)
  const resending = useSelector(
    ({ loading }) => loading.effects.directors.resendConfirmationEmail
  )
  const distributors = useSelector(select.distributors.selectAdminDistributors)
  const { slug } = useParams()

  const [currentDirectorIndex, setCurrentDirectorIndex] = useState()

  const { isAdmin } = useSelector(({ auth }) => ({
    isAdmin: auth.authUser.userInfo?.is_admin
  }))

  async function handleResendConfirmation(email, idx) {
    setCurrentDirectorIndex(idx)
    await dispatch.directors.resendConfirmationEmail({ email })
  }

  useEffect(() => {
    dispatch.applications.applicationDetail({ slug, isAdmin })
  }, [dispatch])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white px-1 md:px-8'>
        <div className='ml-1 flex flex-wrap mt-4'>
          <div className='w-2/3'>
            <h3
              onClick={() => history.goBack()}
              className='font-bold text-lg cursor-pointer mb-4'
            >
              <i className='fas fa-arrow-left mr-3' />
              {VIEW_APPLICATION}
            </h3>
            <div className='flex flex-wrap font-normal justify-start text-light-200'>
              <p className='mr-8'>
                <strong>Loan ID: #{details.loan_id && details.loan_id}</strong>
              </p>
              <p className=''>
                <strong>
                  {APPLICATION_DATE}{' '}
                  {details.loan_application_initiated &&
                    details.loan_application_initiated.slice(0, 10)}
                </strong>
              </p>
            </div>
          </div>
        </div>
        {Object.keys(details).length > 0 ? (
          <div className='ml-1 flex flex-wrap mt-4 mb-10'>
            <div className='w-full'>
              <h2 className='mt-3 mb-5 text-xl font-bold text-light-200'>
                {BIZ_INFORMATION}
              </h2>
            </div>
            <div className='w-full md:w-1/2'>
              {details?.business_email
                ? DETAILS.map((detail, i) => (
                  <div className='mb-3' key={i}>
                    <strong>{detail.title}: </strong>{' '}
                    <span>{details[detail.key]}</span>
                    <hr className='mt-2' />
                  </div>
                ))
                : NEW_BUSINESS_INFORMATION.map((detail, i) => {
                  return (
                    <div className='mb-3' key={i}>
                      <strong>{detail?.title}: </strong>{' '}
                      <span>
                        {details?.loan_business_information[detail?.key]}
                      </span>
                      <hr className='mt-2' />
                    </div>
                  )
                })}
            </div>
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>
                {BIZ_DOCUMENTS}
              </h2>
              {isAdmin ? (
                <p className='my-10 animate-bounce'>
                  For easy document management, please visit{' '}
                  <Link
                    className='font-extrabold text-blue-700 underline'
                    to={`/admin-customers-details/customer-docs/${slug}`}
                  >
                    Manage docs
                  </Link>{' '}
                  and search for{' '}
                  <span className='font-extrabold'>
                    {details?.business_name}
                  </span>{' '}
                  for all the docs for this customer
                </p>
              ) : (
                ''
              )}
            </div>
            <div className='w-full lg:w-4/5'>
              <div className='flex flex-wrap'>
                {details.cac_document_two && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{STATEMENT_SHARE_CAP}</span>
                    <a
                      href={details.cac_document_two}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 2'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details.cac_document_three && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{CHANGE_OF_REG_ADDRESS}</span>
                    <a
                      href={details.cac_document_three}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 3'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details.cac_document_seven && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{APPOINTMENT_OF_DIR}</span>
                    <a
                      href={details.cac_document_seven}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 7'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {/* <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{REQUIRED_BIZ_LICENSE}</span>
                  <a
                    href={details.business_license_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='Business Licence'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p> */}
                {/* <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{REQUIRED_BIZ_LICENSE}</span>
                  <a
                    href={details.business_license_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='Business Licence'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p> */}
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{PARTICULARS_OF_DIRECTORS}</span>
                  <a
                    href={details.business_aoa_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6  '>{SIX_MONTHS_BANK_STATEMENT}</span>
                  <AdminBankStatementViewer
                    details={details}
                    isAdmin={isAdmin}
                  />
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{PHARMACISTS}</span>
                  <a
                    href={details.business_super_pharmacist_lic}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{MEMORANDUM_AND_ARTICLE}</span>
                  <a
                    href={details.business_moa_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                {details?.distributor_vendor_ledger && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6'>Distributors vendor ledger</span>
                    <a
                      href={details.distributor_vendor_ledger}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='Distributor-vendor'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details?.sales_data && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6'>Sales data</span>
                    <a
                      href={details.sales_data}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='Sales-data'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details?.agreement_doc_url && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6'>Agreement documents</span>
                    <a
                      href={details.agreement_doc_url}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='Sales-data'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
              </div>
            </div>
            {details.signatories.length > 0 ? (
              <>
                <div className='w-full'>
                  <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>
                    {BIZ_SIGNATORY}
                  </h2>
                </div>
                <div className='w-full md:w-5/6 lg:w-4/5'>
                  <div className='flex flex-wrap'>
                    {details.signatories.map((user, i) => (
                      <div
                        key={i}
                        className={`w-full mb-6 ${
                          details.signatories.length === 1
                            ? 'p-8 bg-gray-100 md:w-4/5'
                            : 'md:w-1/2'
                        } ${
                          details.signatories.length - 1 > 1 &&
                          details.signatories.length - 1 !== i
                            ? 'py-4 border-b'
                            : ''
                        }`}
                      >
                        <table>
                          <tbody>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {user?.photo.split('.')[
                                  user?.photo.split('.').length - 1
                                ] === 'pdf' ? (
                                    <a
                                      href={user?.photo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download='Picture'
                                      className='pointer'
                                    >
                                      <img
                                        src={pdfImage}
                                        alt='profile'
                                        width='100px'
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      href={user?.photo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download='Picture'
                                      className='pointer'
                                    >
                                      <img
                                        className='transform hover:scale-150 duration-300'
                                        src={
                                          user?.photo?.length > 0
                                            ? user?.photo
                                            : pdfImage
                                        }
                                        alt='profile'
                                        width='100px'
                                      />
                                    </a>
                                  )}
                              </th>
                              <td className=''>
                                <i>
                                  {user.sig_title_role} {user.sig_last_name}{' '}
                                  {user.sig_first_name}
                                </i>
                                <br />
                                <i>{user.sig_email} </i>
                                <br />
                                <i>{user.sig_ph_num}</i>
                              </td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {HOUSE_ADDRESS}
                              </th>
                              <td className=''>
                                {user.sig_address ? user.sig_address : 'Null'}
                              </td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {MARITAL_STATUS}
                              </th>
                              <td className=''>{user.sig_marital_status}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {DATE_OF_BIRTH}
                              </th>
                              <td className=''>{user.sig_birth_date}</td>
                            </tr>
                            <tr className='leading-7' hidden={!isAdmin}>
                              <th className=' pr-6 text-right text-light-200'>
                                {CONFIRMATION_STATUS}
                              </th>
                              <td className=''>
                                {user.acceptance_status || user?.email_verified
                                  ? 'Accepted'
                                  : 'Pending'}
                                {!(
                                  user.acceptance_status || user?.email_verified
                                ) && (
                                  <button
                                    onClick={() =>
                                      handleResendConfirmation(
                                        user.sig_email,
                                        i
                                      )
                                    }
                                    className='ml-5 text-primary font-extrabold hover:bg-blue-400 hover:text-white px-3 rounded-sm'
                                  >
                                    Resend{' '}
                                    {resending && currentDirectorIndex === i ? (
                                      <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                                    ) : (
                                      <img
                                        className='w-1/5 inline'
                                        src={imageUrl}
                                      />
                                    )}
                                  </button>
                                )}
                              </td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {GENDER}
                              </th>
                              <td className=''>{user.sig_gender}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {NO_OF_KIDS}
                              </th>
                              <td className=''>{user.sig_num_kids}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {GOVERNMENT_ID}
                              </th>
                              <td className=''>
                                <a
                                  href={user.government_id}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download='CAC'
                                >
                                  <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {/* Loan details */}
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>
                Loan Details
              </h2>
            </div>

            <div className='w-full'>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Loan Amount:{' '}
                </strong>{' '}
                <span>₦{formatCurrency(details.loan_amount)}</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Loan Duration:{' '}
                </strong>{' '}
                <span>{details.duration_of_loan} months</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Pharmacist License Expiration Date:{' '}
                </strong>{' '}
                <span>{details.lic_expiry}</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Created On:{' '}
                </strong>{' '}
                <span>{ moment(details?.created_at || details?.approval_date || details?.time_submitted).format('YY-MM-DD: mm:hh')}</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Loan Purpose:{' '}
                </strong>{' '}
                <span>{details.loan_purpose}</span>
              </p>
              {details.loan_type && (
                <p>
                  <strong className='text-light-200 font-extrabold'>
                    Loan Type:{' '}
                  </strong>{' '}
                  <span className='mr-5'>{details.loan_type}</span>{' '}
                  <button
                    className='underline text-primary inline px-4 py-2 rounded-md'
                    onClick={() => setModalVisible(true)}
                  >
                    {' '}
                    View Loan Schedule
                  </button>
                </p>
              )}
            </div>

            {/* Distributors details */}
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>
                Distributors Details
              </h2>
            </div>
            <div className='w-full grid gap-5 grid-cols-3 '>
              {details.distributor?.map((item) => (
                <div key={item.value} className='border-r border-b'>
                  <p>
                    <span className='font-extrabold'>Distributor : </span>
                    {
                      distributors?.find(
                        (dist) => item?.distributor_id === dist.id
                      )?.name
                    }
                  </p>
                  <p>
                    <span className='font-extrabold'>Amount : </span>{' '}
                    {item.amount}
                  </p>
                </div>
              ))}
            </div>
            <div className='w-full mt-6'>
              <button
                onClick={() => history.goBack()}
                className='btn-white font-normal hover:text-primary-200 bg-light-blue-1'
              >
                {GO_BACK}
              </button>
            </div>
          </div>
        ) : (
          <div
            className='flex justify-center items-center'
            style={{ height: '60vh' }}
          >
            <h1>
              <img src={loader} className='w-14' alt='Loading...' />
            </h1>
          </div>
        )}

        {previewModalVisible && (
          <LoanTypeModal
            modalVisible={previewModalVisible}
            previewData={{
              loanAmount: details.loan_amount,
              loanDuration: details.duration_of_loan,
              loanType: details.loan_type,
              startDate: details?.created_at || details?.approval_date || details?.time_submitted
            }}
            setModalVisible={setModalVisible}
          />
        )}
      </main>
    </DashboardLayout>
  )
}

export default ApplicationsDetails

/** A simple component to help handle multipple cases of bank statment */
const AdminBankStatementViewer = ({ details }) => {
  if (
    (details.business_bank_statement?.length > 0 &&
      validateURL(details?.business_bank_statement[0]?.file_url)) ||
    validateURL(details?.business_bank_statement)
  ) {
    return (
      <a
        href={
          validateURL(details?.business_bank_statement)
            ? details?.business_bank_statement
            : details?.business_bank_statement[0]?.file_url
        }
        target='_blank'
        id='bank_statement'
        rel='noopener noreferrer'
      >
        <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
      </a>
    )
  }

  if (
    details?.business_bank_statement?.length > 0 &&
    details?.business_bank_statement[0]?.file_json !== null
  ) {
    let statement, statementPasred

    try {
      statementPasred = JSON.parse(details.business_bank_statement)
      statement =
        statementPasred?.file?.length > 0
          ? statementPasred.file
          : statementPasred
    } catch (error) {
      if (error.message?.includes('Unexpected token')) {
        statement = details?.business_bank_statement[0]?.file_json
      } else {
        notification.error({
          message: 'An error occured',
          description:
            'Please visit the manage customer documents section from the side nav to view all documents for this customer'
        })
      }
    }

    return (
      <button
        onClick={() => {
          downloadCSV(bankStatementColumn, statement)
        }}
      >
        <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
      </button>
    )
  }

  return (
    <button
      title={
        details.business_bank_statement === null
          ? 'Bank statement is not available'
          : ''
      }
      onClick={() => {
        if (
          details.business_bank_statement === null ||
          details?.business_bank_statement[0]?.file_json === null
        ) {
          notification.error({
            message: 'Bank statment is not found',
            duration: 6000,
            description: (
              <>
                Go to <a href='/admin-upload-docs'> manage customer details </a>{' '}
                to see all docs for this customer
              </>
            )
          })
        } else {
          const statementPasred = JSON.parse(details.business_bank_statement)
          const statement =
            statementPasred?.file?.length > 0
              ? statementPasred.file
              : statementPasred
          downloadCSV(bankStatementColumn, statement)
        }
      }}
    >
      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
    </button>
  )
}
