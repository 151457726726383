import { OUR_CORE_VALUES, OUR_CORE_VALUES_DATA } from '../constants'
import Card from './CoreValuesCard'

const CoreValues = () => {
  return (
    <main className='flex justify-center px-4 md:px-0 flex-col items-center'>
      <h1 className='text-black text-2xl md:py-5 font-semibold '>{OUR_CORE_VALUES}</h1>
      <div className='grid justify-center mt-4 items-center grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3'>
        {OUR_CORE_VALUES_DATA.map((value, index) => (
          <Card
            key={index}
            icon={<img src={value.icon} className='' />}
            bgColor={value.bgColor}
            title={value.title}
            content={value.content}
          />
        ))}
      </div>
    </main>
  )
}
export default CoreValues
