import React from 'react'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Dragger } = Upload

const AttachDocument = () => {
  const props = {
    name: 'file',
    multiple: true,
    action: '',
    onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {}
  }

  return (
    <div>
      <div className='h-56'>
        <Dragger {...props}>
          <>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text px-4'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint px-4'>
              Support for a single or bulk upload. Strictly prohibit from uploading company data or
              other band files
            </p>
          </>
        </Dragger>
      </div>
    </div>
  )
}

export default AttachDocument
