import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { notification } from 'antd'

import {
  NAME,
  GET_IN_TOUCH,
  CONTACT_OUR_TEAM,
  EMAIL,
  MESSAGE,
  SEND_MEASSGE
} from '../HomePage/constant'

import { ContactUs } from '../../services'
import { emailReg } from '../../utils/inputValidator'

const GetIntouch = () => {
  const { pathname } = useLocation()
  const openRoutes = [
    '/',
    '/about',
    '/faq',
    '/contact-us',
    '/how-it-works',
    '/privacy-policy',
    '/career-page'
  ]

  if (!openRoutes.includes(pathname)) return ''

  const [user, setUser] = useState({ name: '', email: '', message: '' })
  const [loading, setLoading] = useState(false)

  const onChangeText = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const submitFormHandle = async e => {
    e.preventDefault()

    if (!user.name || !user.email || !user.message || !emailReg.test(user.email)) {
      notification.error({
        message: 'Error Occurred',
        description: 'Please fill all field! or Invalid email'
      })
      return false
    }
    if (user.message.length < 10) {
      notification.error({
        message: 'Error Occurred',
        description: 'Message should not be less than 10 charaters!'
      })
      return false
    }
    try {
      setLoading(true)
      const { data } = await ContactUs.contactSupport(user)
      if (data.success) {
        notification.success({
          message: 'Message sent successfully!',
          description: data.message
        })
        setUser({
          name: '',
          email: '',
          message: ''
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something wrong, please try again!...'
      })
      setLoading(false)
    }
  }
  return (
    <div className='w-full'>
      <div className='bg-newBgBlue w-full flex justify-center py-8 px-2'>
        <div className=''>
          <form className='py-8'>
            <div id='footer-contact-form' className=''>
              <p className='font-dm-serif-display text-2xl md:text-4xl tracking-wider md:leading-loose text-center font-extrabold text-white md:px-0 px-5'>
                {GET_IN_TOUCH}
              </p>
              <p className='text-white text-center max-w-lg tracking-wider text-base md:px-0 px-5'>
                {CONTACT_OUR_TEAM}
              </p>
            </div>
            <div className='w-full mx-auto flex flex-col max-w-lg gap-8 mt-4'>
              <div className=''>
                <input
                  className='col-span-2 w-full border px-4 py-4  rounded-lg focus:outline-none placeholder-placeHolderBlue'
                  name='name'
                  type='text'
                  placeholder={NAME}
                  onChange={onChangeText}
                />
              </div>
              <div className=''>
                <input
                  className='col-span-2 w-full border px-4 py-4  rounded-lg focus:outline-none placeholder-placeHolderBlue'
                  name='email'
                  type='email'
                  placeholder={EMAIL}
                  required
                  onChange={onChangeText}
                />
              </div>
              <div className='w-full'>
                <textarea
                  className='col-span-2 focus:to-blue-800 w-full py-12 px-4 placeholder-placeHolderBlue border bg-white rounded-lg resize-none focus:outline-none'
                  name='message'
                  type='text'
                  placeholder={MESSAGE}
                  onChange={onChangeText}
                />
              </div>
              <button
                onClick={submitFormHandle}
                type='submit'
                className=' text-xl w-full justify-center py-4 text-white font-semibold flex bg-subHeading rounded-lg'
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {SEND_MEASSGE}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default GetIntouch
