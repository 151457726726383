import React, { useEffect, useState } from 'react'
import { DatePicker, Table, notification } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import moment from 'moment'
import _debounce from 'lodash/debounce'

import { leadsColumns } from './constant'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { LeadsApi } from '../../../services'
import { searchArrayOrMakeCallToAPI } from '../../../utils/helpers'
import Export from '../../../sharedComponents/export/Export'
import { exportFile } from '../../../utils/export'

const LeadsTable = () => {
  const [loading, setLoading] = useState(false)
  const [leadsData, setLeadsData] = useState([])
  const searchInputRef = React.useRef(null)

  const getLeads = async () => {
    setLoading(true)
    try {
      const { data } = await LeadsApi.getLeads()
      setLeadsData(data.data.contacts)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message || 'Something went wrong'
      })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchDate = (_, date) => {
    if (!date || !_) {
      return getLeads()
    }
    const foundItems = leadsData.filter(useritem =>
      moment(useritem.created_at).isBetween(moment(date[0]), moment(date[1]))
    )

    return setLeadsData(foundItems)
  }

  const handleSearch = async ({ target }) => {
    if (!target.value) {
      return getLeads()
    }

    const resultFound = await searchArrayOrMakeCallToAPI({
      array: leadsData,
      searchTerm: target.value,
      makeSearchCall: []
    })
    return setLeadsData(resultFound)
  }

  const searchUserDebounce = _debounce(handleSearch, 200)

  useEffect(() => {
    getLeads()
  }, [])

  return (
    <DashboardLayout>
      <div className='m-5'>
        <div className='mb-10 flex items-center'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-lg flex-grow '>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 text-lg '>
              <div className='relative mt-6  rounded-md mr-6 ml-0 col-span-3'>
                <div className='absolute top-4 left-2 pl-2'>
                  <button onClick={() => searchInputRef.current.focus()}>
                    <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                  </button>
                </div>
                <div>
                  <input
                    type='text'
                    ref={searchInputRef}
                    onChange={searchUserDebounce}
                    className='h-14 w-full pl-10 border pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                    placeholder='Search for leads'
                  />
                </div>
              </div>
            </div>

            <div className='flex mt-6'>
              <span className=' mt-4'>Filter by:</span>
              <div className='relative flex border rounded-md  ml-3'>
                <DatePicker.RangePicker
                  onChange={handleSearchDate}
                  picker='date'
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
          </div>

          <div className='w-1/6'>
            <Export
              onClick={() => {
                exportFile({ data: leadsData, column: leadsColumns }, '.csv', true)
              }}
            />
          </div>
        </div>
        <Table
          className='mt-5'
          columns={leadsColumns}
          dataSource={leadsData?.map(item => ({ ...item, key: item.id }))}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            hideOnSinglePage: true
          }}
        />
      </div>
    </DashboardLayout>
  )
}

export default LeadsTable
