import {
  OUR_MISSION,
  OUR_MISSION_PARAGRAPH,
  WORK_THAT_MATTERS,
  WORK_THAT_MATTERS_PARAGRAPH
} from '../constants'
import ourMissionImage from '../assets/ourMission.svg'

const WorkThatMatters = () => {
  return (
    <main className='py-5 md:py-16'>
      <div>
        <h1 className='text-lg  md:text-3xl text-black ml-4 md:ml-0 font-semibold'>
          {WORK_THAT_MATTERS}
        </h1>
        <p className='md:text-lg text-sm text-vdfHtexts px-2 md:px-0 ml-2 md:ml-0 mt-2 font-normal max-w-xl md:mt-3'>
          {WORK_THAT_MATTERS_PARAGRAPH}
        </p>
      </div>
      <div className='flex flex-col lg:flex-row mt-5 md:gap-20 md:mt-12'>
        <div className='left w-full lg:w-1/2'>
          <img src={ourMissionImage} alt='Our Mission' className='w-full' />
        </div>
        <div className='right mt-5 md:mt-0   w-full lg:w-1/2'>
          <h1 className='font-semibold ml-4 md:ml-0  font-sans2 text-lg md:text-3xl'>
            {OUR_MISSION}
          </h1>
          <p className='max-w-2xl font-sans2 text-vdfHtexts mt-2 leading-relaxed md:leading-loose text-sm px-2 md:px-0 md:text-xl md:tracking-wider  md:mt-8'>
            {OUR_MISSION_PARAGRAPH}
          </p>
        </div>
      </div>
    </main>
  )
}

export default WorkThatMatters
