const APPROVE_REJECTED_LOANS = 'approve_reject_loan'
const DECLINE_LOAN = 'decline_loan'
const DELETE_LOAN = 'delete_loan'
const CLOSE_LOAN = 'close_loan'
const DISBURSE_LOAN = 'disburse_loan'
const MODIFY_LOAN_APPLICATION = 'modify_loan_application'
const MODIFY_PAYMENTS = 'modify_payments'
const GRANT_REVOKE_ADMIN_ACCESS = 'grant_revoke_admin_access'
const MODIFY_ROLES = 'modify_roles'
const VIEW_LOAN_APPLICATIONS = 'view_loan_applications'
const VIEW_DASHBOARD = 'view_dashboard'
const VIEW_LOANS = 'view_loans'
const VIEW_ADMINISTRATION = 'view_administration'
const VIEW_INTEREST_TRACKER = 'interest_tracker'
const VIEW_TEMPLATES = 'view_templates'
const VIEW_SYSTEM_LOGS = 'view_system_logs'
const APPLY_FOR_LOANS = 'apply_for_loan'
const VIEW_FEEDBACK = 'view_feedback'
const SEND_REMINDER = 'send_reminder'
const VIEW_ROLES = 'view_roles'
const VIEW_USERS = 'view_users'
const ADD_TERMINAL_ID = 'add_terminal_id'
const ALLOW_CREATE_BANK_ACCOUNT = 'allow_create_bank_account'
const ADMIN_CLOSE_LOANS = 'admin_close_loans'
export {
  ALLOW_CREATE_BANK_ACCOUNT,
  APPROVE_REJECTED_LOANS,
  CLOSE_LOAN,
  DECLINE_LOAN,
  DISBURSE_LOAN,
  MODIFY_LOAN_APPLICATION,
  MODIFY_PAYMENTS,
  MODIFY_ROLES,
  VIEW_LOAN_APPLICATIONS,
  VIEW_ROLES,
  VIEW_USERS,
  APPLY_FOR_LOANS,
  VIEW_DASHBOARD,
  VIEW_LOANS,
  VIEW_ADMINISTRATION,
  VIEW_TEMPLATES,
  VIEW_SYSTEM_LOGS,
  VIEW_INTEREST_TRACKER,
  VIEW_FEEDBACK,
  SEND_REMINDER,
  GRANT_REVOKE_ADMIN_ACCESS,
  DELETE_LOAN,
  ADMIN_CLOSE_LOANS,
  ADD_TERMINAL_ID
}
