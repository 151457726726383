import React from 'react'
import { BUTTON_TEXT, HEADER_PARAGRAPH, HEADER_TEXT } from '../constants'

export const CareerHeader = () => {
  return (
    <div>
      <div className='py-10 mt-8 md:mt-14 flex flex-col justify-center items-center'>
        <h1 className='font-sans2 text-2xl md:text-7xl text-vittasBlue font-semibold tracking-wider lg:leading-loose'>
          {HEADER_TEXT}
        </h1>
        <p className='px-6 font-sans2 text-center text-lg md:text-xl md:px-2 max-w-lg leading-relaxed lg:max-w-2xl text-header-txt tracking-wider '>
          {HEADER_PARAGRAPH}
        </p>
        <button className='btn-primary font-sans2  text-white bg-primary mt-8'>
          {BUTTON_TEXT}
        </button>
      </div>
    </div>
  )
}
