import { useRef } from 'react'
import ApplicationFormSection from '../../sharedComponents/finance-landing/ApplicationFormSection'
import DistinctiveAspectSection from '../../sharedComponents/finance-landing/DistinctiveAspectSection'
import HeroSection from '../../sharedComponents/finance-landing/HeroSection'
import KeyFeatureSection from '../../sharedComponents/finance-landing/KeyFeatureSection'
import WhoShouldApplySection from '../../sharedComponents/finance-landing/WhoShouldApplySection'

const HealthFinanceLanding = () => {
  const formRef = useRef(null)
  const executeScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <div>
      <HeroSection executeScroll={executeScroll} />
      <div className='flex flex-col mx-4 md:mx-20 gap-16 md:gap-20 mt-16 md:mt-20'>
        <KeyFeatureSection />
        <div className='mb-8'>
          <DistinctiveAspectSection />
        </div>
        <WhoShouldApplySection executeScroll={executeScroll} />
        <div ref={formRef}>
          <ApplicationFormSection />
        </div>
      </div>
    </div>
  )
}

export default HealthFinanceLanding
