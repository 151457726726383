import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ icon, bgColor, title, content }) => {
  return (
    <div className='rounded-lg h-full max-w-sm shadow-lg'>
      <div
        className={`bg-${bgColor} flex items-center justify-center rounded-t-3xl py-5 w-full shadow-md`}
      >
        {icon}
      </div>
      <div className='bg-white rounded-b-lg p-6'>
        <h2 className='text-xl text-Heading font-semibold'>{title}</h2>
        <p className='text-header-txt tracking-widest text-sm md:text-lg mt-3'>{content}</p>
      </div>
    </div>
  )
}
Card.propTypes = {
  icon: PropTypes.node.isRequired,
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}

export default Card
