/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { BACK, PUSH_NOTIFICATIONS, SEND_MESSAGE } from './constant'
import NotificationCard from './NotificationCard'
import { adminServices } from '../../services'
import { notification, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../redux/store'
import { AntDesignFormSelect } from '../../sharedComponents/form-select/FormSelect'

const PushNotificationComponent = () => {
  const [loading, setLoading] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [showNewConversation, setShowNewConversation] = useState(true)
  const [refreshKey, setRefreshKey] = useState(0)
  const [messageDetails, setMessageDetails] = useState({
    users: [],
    message_body: '',
    message_header: '',
    user_id_consent: 1
  })

  const existingUsers = useSelector(select.users.getUsersNotAdmin)

  const resetForm = () => {
    setRefreshKey(Math.random())
    setMessageDetails({
      users: [],
      message_body: '',
      message_header: '',
      user_id_consent: 1
    })
  }

  const handleBackButtonClick = () => {
    setSelectedNotification(null)
    setShowNewConversation(true) // Show new conversation interface when clicking back
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setMessageDetails({ ...messageDetails, [name]: value })
  }

  const handleUserChange = selectedUsers => {
    setMessageDetails(prevDetails => ({ ...prevDetails, users: selectedUsers }))
  }

  const handleSendMessage = async () => {
    const { message_body, message_header, users } = messageDetails

    if (users?.length < 1) {
      notification.error({
        message: 'Incorrect input',
        description: 'Select at least one user'
      })

      return
    }

    if (message_body.length < 3 || message_header.length < 3) {
      notification.error({
        message: 'Incorrect input',
        description: 'Message body or title must be at least three characters'
      })

      return
    }
    setLoading(true)
    try {
      const { data } = await adminServices.adminSendMessage(
        {
          ...messageDetails,
          users: messageDetails.users
        },
        'PUSH_NOTIFICATION'
      )
      const { success, message } = data
      setNotifications(message)
      if (success) {
        notification.success({
          message: message || 'Message has been sent successfully',
          duration: 3
        })
        resetForm()
      } else {
        throw new Error(message || 'Failed to send message')
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      let errorMessage = 'An unexpected error occurred. Please try again.'

      if (error.response) {
        errorMessage = error.response.data.message || error.response.data.error || errorMessage
      } else if (error.request) {
        errorMessage = 'No response received from server. Please check your connection.'
      } else if (error.message) {
        errorMessage = error.message
      }

      notification.error({
        message: 'Error Sending Message',
        description: errorMessage,
        duration: 5
      })
    }
  }

  useEffect(() => {
    if (!existingUsers || existingUsers.length === 0) {
      dispatch.users.getAllusers({
        showAll: true
      })
    }
  }, [])

  return (
    <div className='flex w-full mb-8 px-3 flex-col justify-between'>
      <div className='flex items-center py-3 justify-between'>
        <h2 className='text-lg font-bold mb-4'>{PUSH_NOTIFICATIONS}</h2>
      </div>
      <div className='flex gap-4'>
        {/* Left Side: Messages */}
        <div className='w-1/2 bg-white p-4'>
          {/* Example message cards */}
          <div className='flex flex-col space-y-4'>
            <div className='cursor-pointer'>
              <NotificationCard description={notifications} />
            </div>
          </div>
        </div>

        {/* Right Side: Detailed Notification or New Conversation */}
        <div className='w-full relative h-80vh bg-white'>
          {selectedNotification && !showNewConversation ? (
            <div className='p-4'>
              <h3 className='text-lg font-bold mb-2'>{selectedNotification.name}</h3>
              <p className='text-sm text-gray-600 mb-2'>{selectedNotification.datetime}</p>
              <p>{selectedNotification.description}</p>
              <button
                className='bg-gray-200 text-gray-700 px-3 py-1 mt-4 rounded'
                onClick={handleBackButtonClick}
              >
                {BACK}
              </button>
            </div>
          ) : (
            <div className='p-4'>
              <AntDesignFormSelect
                key={refreshKey}
                mode='multiple'
                options={existingUsers.map(
                  ({ first_name: firstName, last_name: lastName, id }) => ({
                    label: `${firstName} ${lastName}`,
                    value: id
                  })
                )}
                value={messageDetails.users}
                onChange={handleUserChange}
                placeholder='Select Users'
                name='users'
              />
              <input
                type='text'
                placeholder='Subject'
                name='message_header'
                value={messageDetails.message_header}
                onChange={handleInputChange}
                className='w-full border-b py-3 border-gray-300 px-3 focus:outline-none focus:border-blue-500 mb-2'
              />
              <textarea
                placeholder='Type message here'
                name='message_body'
                value={messageDetails.message_body}
                onChange={handleInputChange}
                className='w-full focus:outline-none my-2'
              />
              <div className='flex absolute bottom-6 border-gray-200 border-t px-4 pt-4 w-full gap-4 items-center'>
                <button
                  className='bg-vittasBlue hover:bg-blue-600 text-white px-4 py-2 rounded'
                  onClick={handleSendMessage}
                >
                  {loading ? (
                    <div>
                      <Spin className='pr-2' /> Loading...
                    </div>
                  ) : (
                    SEND_MESSAGE
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PushNotificationComponent
