/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import { Loans } from '../../../../services'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'
import { applyLoanlocalStorageKey, steps } from '../constants'
import { formatStepData, getLocalStorageLoanApplication } from '../../../../utils/helpers'
import { Loader } from '../../../../sharedComponents'

const ResumeLoanApplication = () => {
  const loanId = useParams().loanId
  const history = useHistory()

  async function getLoanApplicationBySteps() {
    try {
      const arrayOfPromises = [1, 2, 3, 4].map(step =>
        Loans.getLoanAplicationBySteps({ loan_id: loanId }, step)
      )
      const serverResponseForSteps = await Promise.allSettled(arrayOfPromises)
      const successFullSteps = serverResponseForSteps
        .filter(step => step.status === 'fulfilled')
        .map(step => step.value.data)

      // store the data based on steps in the local storage
      let disableStepThree = false
      successFullSteps.forEach(item => {
        const previousLocalStorageItems = getLocalStorageLoanApplication()
        const currentStep = item.data.step
        const currentStepItemFromLocalStorage = previousLocalStorageItems?.[steps[currentStep]]
        const formatedData = formatStepData(currentStep, item.data[steps[currentStep]])
        if (currentStep === 3) {
          disableStepThree = Boolean(formatedData)
        }

        window.localStorage.setItem(
          applyLoanlocalStorageKey,
          JSON.stringify({
            ...previousLocalStorageItems,

            [steps[currentStep]]: {
              ...currentStepItemFromLocalStorage,
              ...formatedData,
              loan_id: loanId
            }
          })
        )
      })

      if (successFullSteps.length > 0) {
        history.push(
          `/apply-for-loan/${
            successFullSteps[0]?.data?.[steps[1]]?.userId
          }?disableStepThree=${disableStepThree}`
        )
      } else {
        window.localStorage.removeItem(applyLoanlocalStorageKey)
        notification.warning({
          message: 'The application has corrupted, please start afresh',
          onClose: () => history.push('/admin-pending')
        })
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Error! Please consider starting afresh',
        onClose: () => history.push('/admin-pending')
      })
    }
  }

  useEffect(() => {
    if (!loanId) {
      notification.warn('There is no Loan ID provided')
    } else {
      getLoanApplicationBySteps()
    }
  }, [])

  return (
    <DashboardLayout>
      <Loader color='blue' height={400} width={400} />
    </DashboardLayout>
  )
}

export default ResumeLoanApplication
